<template>
    <section id="traiteHarmony">
        <div class="d-desk">
            <div class="option-slide">
                <figure class="bg-brush"><img :src='this.$pathprod + "images/traite_harmony/brush.png"' alt="bg-brush"></figure>
                <ul class="slide-lane">
                    <li class="slide-lane-logo" v-for="(logo, itemNumber) in logos" :key="itemNumber">
                        <figure><img v-bind:src="logo.src" :srcset="logo.srcset" :alt="logo.alt"></figure>
                    </li>
                </ul>
            </div>
            <div class="option-content">
                <h1 class="title-h2 text-focus-in">{{$t('titleTraiteHarmony')}}</h1>
                <h2 class="txt-gold">{{$t('dataTraite')['0']}}</h2>
                <p>{{$t('dataTraite')['1']}}</p>
                <p>
                    <strong>{{$t('dataTraite')['2']}}</strong> {{$t('dataTraite')['3']}}
                </p>
                <div class="txt-gold ctn-bottom">
                    <div>{{$t('dataTraite')['4']}}</div>
                    <div>{{$t('dataTraite')['5']}}</div>
                </div>
            </div>

            <div class="d-mobile">
                <div class="d-mobile-img">
                    <figure v-for="index in logosMobile" :key="index.id"><img v-bind:src="index.src" :alt="index.alt"></figure>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'traiteHarmony',
        props: {
            msg: String,
        },

        metaInfo() {
            return { 
                title: "Harmony - Traité d’harmony",
                meta: [
                    { name: 'description', content:  'Le savoir-faire est au coeur du projet qui se veut engagé et durable. 7 certifications et labels qui garantissent un niveau de performance exceptionnel'},
                    { property: 'og:title', content: "Harmony - Traité d’harmony"},
                    { property: 'og:site_name', content: 'Harmony'},
                    { property: 'og:type', content: 'website'},    
                ]
            }
        },
        data: () => ({
            observer: null,
            logos:[
                {src: '/images/traite_harmony/wired.svg', ref:'wired', alt:'logo-wired'},
                {src: '/images/traite_harmony/breeam.svg', ref:'breeam', alt:'logo-breeam'},
                {src: '/images/traite_harmony/bbc.svg', ref:'bbc', alt:'logo-bbcEffinergie'},
                {src: '/images/traite_harmony/biodiuerCity.svg', ref:'biodiuerCity', alt:'logo-biodiuerCity'},
                {src: '/images/traite_harmony/ec.svg', ref:'ec', alt:'logo-ec'},
                {src: '/images/traite_harmony/hqe.svg', ref:'hqe', alt:'logo-hqe'},
                {src: '/images/traite_harmony/well.svg', ref:'well', alt:'logo-well'},
                {src: '/images/traite_harmony/wired.svg', ref:'wired', alt:'logo-wired'},
                {src: '/images/traite_harmony/breeam.svg', ref:'breeam', alt:'logo-breeam'},
                {src: '/images/traite_harmony/bbc.svg', ref:'bbc', alt:'logo-bbcEffinergie'},
                {src: '/images/traite_harmony/biodiuerCity.svg', ref:'biodiuerCity', alt:'logo-biodiuerCity'},
                {src: '/images/traite_harmony/ec.svg', ref:'ec', alt:'logo-ec'},
                {src: '/images/traite_harmony/hqe.svg', ref:'hqe', alt:'logo-hqe'},
                {src: '/images/traite_harmony/well.svg', ref:'well', alt:'logo-well'},
                {src: '/images/traite_harmony/wired.svg', ref:'wired', alt:'logo-wired'},
                {src: '/images/traite_harmony/breeam.svg', ref:'breeam', alt:'logo-breeam'},
                {src: '/images/traite_harmony/bbc.svg', ref:'bbc', alt:'logo-bbcEffinergie'},
                {src: '/images/traite_harmony/biodiuerCity.svg', ref:'biodiuerCity', alt:'logo-biodiuerCity'},
                {src: '/images/traite_harmony/ec.svg', ref:'ec', alt:'logo-ec'},
                {src: '/images/traite_harmony/hqe.svg', ref:'hqe', alt:'logo-hqe'},
                {src: '/images/traite_harmony/well.svg', ref:'well', alt:'logo-well'},
            ],
            logosMobile:[
                {id:1, src: '/images/traite_harmony/wired.svg', ref:'wired', alt:'logo-wired'},
                {id:2, src: '/images/traite_harmony/breeam.svg', ref:'breeam', alt:'logo-breeam'},
                {id:3, src: '/images/traite_harmony/bbc.svg', ref:'bbc', alt:'logo-bbcEffinergie'},
                {id:4, src: '/images/traite_harmony/biodiuerCity.svg', ref:'biodiuerCity', alt:'logo-biodiuerCity'},
                {id:5, src: '/images/traite_harmony/well.svg', ref:'well', alt:'logo-well'},
                {id:6, src: '/images/traite_harmony/hqe.svg', ref:'hqe', alt:'logo-hqe'},
                {id:7, src: '/images/traite_harmony/ec.svg', ref:'ec', alt:'logo-ec'},
            ]
        }),
    };
</script>